import { Badge, Box, Container, ContentLayout, Header, SpaceBetween } from "@cloudscape-design/components";
import { PageHeader } from "../commons/pageHeader";
import { getMatches, getPlayerName } from "../services/firestore";

export function Results(props: any) {
    const pageConfig = {
        name: "Results",
        url: "/results",
        description: "Alle Ergebnisse der letzten 2 Jahre auf einen Blick"
    }

    return (
        <ContentLayout
            header={<PageHeader app={props.app} page={pageConfig}></PageHeader>}
        >
            <Container
                header={
                    <Header
                        variant="h2"
                        description="Alle Matches der letzten 24 Monate"
                    >
                        Overview
                    </Header>
                }>
                <SpaceBetween size={"s"}>
                    {getMatches().map(match => <ResultsContainer
                        firstPlayer={getPlayerName(match.firstPlayer)}
                        secondPlayer={getPlayerName(match.secondPlayer)}
                        firstScore={match.firstScore}
                        secondScore={match.secondScore}
                        type={match.type}
                        coefficient={match.coefficient}
                        date={match.date.toLocaleString()}
                        winner={getPlayerName(match.winner)}
                    ></ResultsContainer>)}
                </SpaceBetween>
            </Container>
        </ContentLayout>
    );
}

function ResultsContainer(props: any) {
    console.log(props.date)

    return (
        <Container
            header={<Header>{props.date}</Header>}
            footer={<SpaceBetween direction="horizontal" size={"s"}>
                <Badge>Coefficient:{props.coefficient}</Badge>
                <Badge>Type:{props.type}</Badge>
            </SpaceBetween>}
        >
            <SpaceBetween direction="horizontal" size={"s"}>
                {props.firstPlayer === props.winner ? <Box variant="h1" color="text-status-success">{props.firstPlayer}</Box> : <Box variant="h1">{props.firstPlayer}</Box>}
                <Box variant="h1">{props.firstScore} : {props.secondScore}</Box>
                {props.secondPlayer === props.winner ? <Box variant="h1" color="text-status-success">{props.secondPlayer}</Box> : <Box variant="h1">{props.secondPlayer}</Box>}
            </SpaceBetween>
        </Container>
    )
}