import { addDoc, collection, deleteDoc, doc, getDocs, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import { database } from "../services/firebase";
import { Badge } from "@cloudscape-design/components";
import { User } from "../commons/interfaces/User"
import { Match } from "../commons/interfaces/Match";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

let users: User[] = []
let matches: Match[] = []
let matchesLastYear: Match[] = []

export async function init() {
    console.log("Initializing Firestore Service")
    //get all Users and store to array
    // eslint-disable-next-line
    const unsubscribeUsers = onSnapshot(query(collection(database, "users")), (querySnapshot) => {
        users = querySnapshot.docs.map(doc => ({
            email: doc.data().email,
            displayName: doc.data().displayName,
            id: doc.id,
            admin: doc.data().admin
        } as User))
    })

    //get all matches and store to array
    const dueDate = new Date()
    dueDate.setFullYear(new Date().getFullYear() - 2)
    // eslint-disable-next-line
    const unsubscribeMatches = onSnapshot(query(collection(database, "matches"), where("date", ">", dueDate), orderBy("date", "desc")), (querySnapshot) => {
        matches = querySnapshot.docs.map(doc => ({
            coefficient: doc.data().coefficient,
            date: doc.data().date.toDate(),
            firstPlayer: doc.data().firstPlayer,
            firstScore: doc.data().firstScore,
            secondPlayer: doc.data().secondPlayer,
            secondScore: doc.data().secondScore,
            type: doc.data().type,
            winner: doc.data().winner,
            id: doc.id
        } as Match))
    })

    //get all matches las Year and store to array
    const dueDateLastYear = new Date()
    dueDate.setFullYear(new Date().getFullYear() - 3)
    const endDateLastYear = new Date()
    endDateLastYear.setFullYear(new Date().getFullYear() - 1)
    await getDocs(query(collection(database, "matches"), where("date", ">", dueDateLastYear), where("date", "<", endDateLastYear))).then(result => {
        matchesLastYear = result.docs.map(doc => ({
            coefficient: doc.data().coefficient,
            date: doc.data().date,
            firstPlayer: doc.data().firstPlayer,
            firstScore: doc.data().firstScore,
            secondPlayer: doc.data().secondPlayer,
            secondScore: doc.data().secondScore,
            type: doc.data().type,
            winner: doc.data().winner,
            id: doc.id
        } as Match))
    })
}

export function getUserID(email: string | null | undefined) {
    return users.find(user => user.email === email)?.id
}

export function getUserByID(id: string) {
    return users.find(user => user.id === id)
}

export async function userExists(email: string | null) {
    const q = query(collection(database, "users"), where("email", "==", email))
    const snapshot = await getDocs(q)
    if (snapshot.docs.length > 0) {
        return true
    } else {
        return false
    }
}

export function userIsAdmin(email: string | null) {
    return users.find(user => user.email === email)?.admin
}

export function getPointsOfUser(id: string | undefined) {
    let score = 0

    matches.filter(match => match.winner === id).forEach(match => {
        score += match.coefficient * 10
    })

    return score
}

export function getPlayedMatchesOfUser(id: string | undefined) {
    return matches.filter(match => match.firstPlayer === id || match.secondPlayer === id).length
}

export function getWonMatchesofUser(id: string | undefined) {
    return matches.filter(match => match.winner === id).length
}

function getChangeFromUser(id: string) {
    let changed = getPointsOfUser(id) - getPointsOfUserFromLastYear(id)
    if (changed < 0) {
        return <Badge color="red">{changed}</Badge>
    } else {
        return <Badge color="green">{changed}</Badge>
    }
}

export function getTable() {
    let items: { position: number; name: any; matches: number; points: number; change: any; }[] = []
    let position: number = 1
    users.sort((a, b) => {
        if (getPointsOfUser(a.id) > getPointsOfUser(b.id)) return -1
        if (getPointsOfUser(a.id) < getPointsOfUser(b.id)) return 1
        if (getPlayedMatchesOfUser(a.id) > getPlayedMatchesOfUser(b.id)) return 1
        if (getPlayedMatchesOfUser(a.id) < getPlayedMatchesOfUser(b.id)) return -1
        return 0
    }).forEach(user => {
        items.push({
            position: position,
            name: user.displayName,
            matches: getPlayedMatchesOfUser(user.id),
            points: getPointsOfUser(user.id),
            change: getChangeFromUser(user.id)
        })

        position++
    })

    return items
}

function getPointsOfUserFromLastYear(id: string) {
    let score = 0

    matchesLastYear.filter(match => match.winner === id).forEach(match => {
        score += match.coefficient * 10
    })

    return score
}


export function getAllPlayers() {
    return users
}

export function getPlayerName(id: string) {
    return users.find(user => user.id === id)?.displayName
}

export async function createPlayer(displayName: string, email: string, isAdmin: boolean) {
    await addDoc(collection(database, "users"), {
        displayName: displayName,
        email: email,
        admin: isAdmin
    })
}

export async function updatePlayer(displayName: string, email: string, isAdmin: boolean, id: string) {
    await setDoc(doc(database, "users", id), {
        displayName: displayName,
        email: email,
        admin: isAdmin
    })
}

export async function deletePlayer(id: string) {
    await deleteDoc(doc(database, "users", id))
}

export function getMatches() {
    return matches
}

export async function deleteMatch(id: string) {
    await deleteDoc(doc(database, "matches", id))
}

export async function createMatch(firstPlayer: OptionDefinition, secondPlayer: OptionDefinition, score1: number, score2: number, type: OptionDefinition) {
    let coefficient = 0
    let winner = ""
    if (firstPlayer.value && secondPlayer.value && type.value) {
        if (score1 > score2) {
            winner = firstPlayer.value
        } else {
            winner = secondPlayer.value
        }

        switch (type.value) {
            case "friendly":
                coefficient = 0.25
                break;
            case "league":
                coefficient = 0.75
                break;
            case "tournament-group":
                coefficient = 0.5
                break;
            case "tournament-knockout":
                coefficient = 0.75
                break;
            case "tournament-final":
                coefficient = 1
                break;
            default:
                break;
        }


        await addDoc(collection(database, "matches"), {
            firstPlayer: firstPlayer.value,
            secondPlayer: secondPlayer.value,
            firstScore: score1,
            secondScore: score2,
            date: new Date(),
            type: type.value,
            winner: winner,
            coefficient: coefficient
        } as Match)
    }

}

export function getMatchesByType() {
    let data = [
        { title: "Friendly", value: 0 },
        { title: "League", value: 0 },
        { title: "Tournament Group", value: 0 },
        { title: "Tournament KnockOut", value: 0 },
        { title: "Tournament Final", value: 0 }
    ]

    matches.forEach(match => {
        switch (match.type) {
            case "friendly":
                data[0].value++
                break;
            case "league":
                data[1].value++
                break;
            case "tournament-group":
                data[2].value++
                break;
            case "tournament-knockout":
                data[3].value++
                break;
            case "tournament-final":
                data[4].value++
                break;
            default:
                break;
        }
    })

    return data
}

export function getNumberOfMatches() {
    return matches.length
}