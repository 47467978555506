import { Box, Container, ContentLayout, Header, SpaceBetween, Table } from "@cloudscape-design/components";
import { PageHeader } from "../commons/pageHeader";
import { getTable } from "../services/firestore";


export function Overview(props: any) {
    const pageConfig = {
        name: "Overview",
        url: "/",
        description: "Alle wichtigen Infos auf einen Blick"
    }

    return (
        <ContentLayout
            header={<PageHeader app={props.app} page={pageConfig}></PageHeader>}
        >
            <Container
                header={
                    <Header
                        variant="h2"
                        description="Berücksichtigt werden die wie in der Anleitung beschrieben gesammelten Punkte der letzten 24 Monate"
                    >
                        Tabelle
                    </Header>
                }>
                <Table
                    columnDefinitions={[
                        {
                            id: "position",
                            header: "#",
                            cell: item => item.position || "-",
                        },
                        {
                            id: "name",
                            header: "Name",
                            cell: item => item.name || "-",
                        },
                        {
                            id: "matches",
                            header: "Matches",
                            cell: item => item.matches || "-"
                        },
                        {
                            id: "points",
                            header: "Score",
                            cell: item => item.points.toFixed(2)
                        },
                        {
                            id: "change",
                            header: "Change from last Year",
                            cell: item => item.change || "-"
                        }
                    ]}
                    items={getTable()}
                    loadingText="Loading resources"
                    sortingDisabled
                    stickyHeader
                    stripedRows
                    variant="embedded"
                    wrapLines
                    empty={
                        <Box
                            margin={{ vertical: "xs" }}
                            textAlign="center"
                            color="inherit"
                        >
                            <SpaceBetween size="m">
                                <b>Bis jetzt wurden keine Matches gespielt</b>
                            </SpaceBetween>
                        </Box>
                    }
                />
            </Container>
        </ContentLayout>
    );
}