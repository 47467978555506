import { Alert, Button, ContentLayout} from "@cloudscape-design/components";
import { PageHeader } from "../commons/pageHeader";
import { useNavigate } from "react-router-dom";


export function Error403(props: any) {
    const pageConfig = {
        name: "Error - 403: Du darfst diese Seite nicht sehen!",
        url: "/error-403"
    }

    const navigate = useNavigate();

    return (
        <ContentLayout
            header={<PageHeader app={props.app} page={pageConfig}></PageHeader>}
        >
            <Alert 
            type="error" 
            header={pageConfig.name}
            action={<Button onClick={e => navigate("/login")}>Back to Login</Button>}
            ></Alert>
        </ContentLayout>
    );
}