import { Button, Container, ContentLayout, Header, Icon } from "@cloudscape-design/components";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../services/firebase";
import { userExists } from "../services/firestore";
import { PageHeader } from "../commons/pageHeader";
import { useNavigate } from "react-router-dom";

const googleProvider = new GoogleAuthProvider();

async function isUserAllowedToSignIn(email: string | null): Promise<boolean> {
    const result = await userExists(email);
    return result;
}

export function Login(props: any) {
    const pageConfig = {
        name: "Login",
        url: "/login"
    }

    const navigate = useNavigate()

    return (
        <ContentLayout
            header={<PageHeader app={props.app} page={pageConfig}></PageHeader>}
        >
            <Container
                header={
                    <Header variant="h2">
                        Login
                    </Header>
                }>
                <Button variant="primary" onClick={() => {
                    signInWithPopup(auth, googleProvider)
                        .then(async (result) => {
                            if (await isUserAllowedToSignIn(result.user.email)) {
                                navigate("/")
                            } else {
                                auth.signOut()
                                navigate("/error-403")
                            }
                        }).catch((error) => {
                        })
                }}>Login with Google <Icon name="external" variant="normal" /></Button>
            </Container>
        </ContentLayout >
    );
}