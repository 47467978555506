import { Box, Button, Container, ContentLayout, Header, Modal, SpaceBetween, Table } from "@cloudscape-design/components"
import { PageHeader } from "../commons/pageHeader";
import { deleteMatch, getMatches, getPlayerName } from "../services/firestore";
import { useNavigate } from "react-router-dom";
import React from "react";

export function AdminResults(props: any) {
    const pageConfig = {
        name: "Results",
        url: "/admin-results",
        description: "Hier können Matches verwaltet werden"
    }
    const [showDeletionModal, setShowDeletionModal] = React.useState(false)
    const [selectedItem, setSelectedItem] = React.useState({
        id: "",
        date: new Date()
    })
    const navigate = useNavigate()

    return (
        <ContentLayout
            header={<PageHeader app={props.app} page={pageConfig}></PageHeader>}
        >
            <Container
                header={
                    <Header
                        variant="h2"
                        actions={<Button variant="primary" onClick={e => {
                            e.preventDefault()
                            navigate("/create-match")
                        }}
                        >
                            Create Match</Button>}
                    >
                        Overview
                    </Header>
                }>
                <Table
                    columnDefinitions={[
                        {
                            id: "date",
                            header: "Date",
                            cell: item => item.date.toLocaleString() || "-",
                        },
                        {
                            id: "firstPlayer",
                            header: "Player 1",
                            cell: item => getPlayerName(item.firstPlayer) || "-",
                        },
                        {
                            id: "secondPlayer",
                            header: "Player 2",
                            cell: item => getPlayerName(item.secondPlayer)
                        },
                        {
                            id: "score",
                            header: "Result",
                            cell: item => item.firstScore + " : " + item.secondScore
                        },
                        {
                            id: "type",
                            header: "Type",
                            cell: item => item.type
                        },
                        {
                            id: "coefficient",
                            header: "Coefficient",
                            cell: item => item.coefficient
                        },
                        {
                            id: "actions",
                            header: "Actions",
                            cell: item => {
                                return (<Box float="right"><SpaceBetween direction="horizontal" size={"s"}><Button variant="icon" iconName="settings" disabled /><Button variant="icon" iconName="remove" onClick={e => {
                                    setSelectedItem({
                                        id: item.id,
                                        date: item.date
                                    })
                                    setShowDeletionModal(true)
                                }} /></SpaceBetween></Box>)
                            },
                            width: 110
                        }
                    ]}
                    items={getMatches()}
                    loadingText="Loading resources"
                    sortingDisabled
                    stickyHeader
                    stripedRows
                    variant="embedded"
                    wrapLines
                    empty={
                        <Box
                            margin={{ vertical: "xs" }}
                            textAlign="center"
                            color="inherit"
                        >
                            <SpaceBetween size="m">
                                <b>Bis jetzt wurden keine Spieler angelegt</b>
                            </SpaceBetween>
                        </Box>
                    }
                />
                <Modal
                    visible={showDeletionModal}
                    onDismiss={e => setShowDeletionModal(false)}
                    header={`Delete Match?`}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link" onClick={e => setShowDeletionModal(false)}>Cancel</Button>
                                <Button variant="primary" onClick={e => {
                                    deleteMatch(selectedItem.id).then(result => {
                                        setShowDeletionModal(false)
                                    })
                                }}>Yes</Button>
                            </SpaceBetween>
                        </Box>
                    }
                >Möchtest du das Match vom {selectedItem.date.toLocaleString()} wirklich löschen?</Modal>
            </Container>
        </ContentLayout>
    )
}