import { Button, Checkbox, Container, ContentLayout, Form, FormField, Header, Input, SpaceBetween } from "@cloudscape-design/components"
import { PageHeader } from "../commons/pageHeader";
import React from "react";
import { createPlayer } from "../services/firestore";
import { useNavigate } from "react-router-dom";

export function CreatePlayer(props: any) {
    const pageConfig = {
        name: "Create Player",
        url: "/create-player",
        description: "Neuen Spieler erstellen"
    }

    const [checked, setChecked] = React.useState(false);
    const [displayName, setDisplayName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const navigate = useNavigate()

    return (
        <ContentLayout
            header={<PageHeader app={props.app} page={pageConfig}></PageHeader>}
        >

            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={e => navigate("/admin-players")} variant="link">
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={e => {
                            createPlayer(displayName, email, checked).then(result => navigate("/admin-players"))
                        }}>Submit</Button>
                    </SpaceBetween>
                }
            >
                <SpaceBetween size={"m"}>
                    <Container
                        header={
                            <Header variant="h2">
                                Personal Information
                            </Header>
                        }>
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="Display Name">
                                <Input value={displayName} onChange={e => setDisplayName(e.detail.value)} />
                            </FormField>
                            <FormField label="E-Mail Adress">
                                <Input value={email} inputMode="email" onChange={e => setEmail(e.detail.value)} />
                            </FormField>
                        </SpaceBetween>
                    </Container>
                    <Container
                        header={
                            <Header variant="h2">
                                Admin Settings
                            </Header>
                        }>
                        <SpaceBetween direction="vertical" size="l">
                            <FormField>
                                <Checkbox
                                    onChange={({ detail }) =>
                                        setChecked(detail.checked)
                                    }
                                    checked={checked}
                                >
                                    Is Player an Admin?
                                </Checkbox>
                            </FormField>
                        </SpaceBetween>
                    </Container>
                </SpaceBetween>
            </Form>

        </ContentLayout>
    )
}