import { Alert, Button, ContentLayout} from "@cloudscape-design/components";
import { PageHeader } from "../commons/pageHeader";
import { useNavigate } from "react-router-dom";


export function Error404(props: any) {
    const pageConfig = {
        name: "Error - 404: Diese Seite existiert nicht!",
        url: "/error-404"
    }

    const navigate = useNavigate();

    return (
        <ContentLayout
            header={<PageHeader app={props.app} page={pageConfig}></PageHeader>}
        >
            <Alert 
            type="error" 
            header={pageConfig.name}
            action={<Button onClick={e => navigate("/")}>Back to Home</Button>}
            ></Alert>
        </ContentLayout>
    );
}