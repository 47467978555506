import React from 'react';
import './App.css';
import { AppLayout, HelpPanel } from '@cloudscape-design/components';
import { Overview } from './pages/overview';
import { AdminPlayers } from './pages/admin-players'
import { Route, Routes } from 'react-router-dom';
import { Login } from './pages/login';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './services/firebase';
import { Error403 } from './pages/403';
import { Error404 } from './pages/404';
import { AppNavigation } from './commons/sideNavigation';
import { userIsAdmin } from './services/firestore';
import { CreatePlayer } from './pages/create-player';
import { Results } from './pages/results';
import { AdminResults } from './pages/admin-results';
import { CreateMatch } from './pages/create-match';
import { MyProfile } from './pages/my-profile';
import { Statistics } from './pages/statistics';
import { ModifyPlayer } from './pages/modify-player';

function App() {
  const [isAuthenticated, setIsAuthenticated] = React.useState(
    false
  )

  const [isAdmin, setIsAdmin] = React.useState(
    false
  )

  const appConfig = {
    name: "OLX Darts Ranking",
    url: "/"
  }



  onAuthStateChanged(auth, (user) => {
    if (user) {
      setIsAuthenticated(true)
      if(userIsAdmin(user.email)){
        setIsAdmin(true)
      }else{
        setIsAdmin(false)
      }
    } else {
      setIsAuthenticated(false)
      setIsAdmin(false)
    }
  })

  return (
    <AppLayout
      // notifications={<Alert>Test</Alert>}
      content={
        <Routes>
          {/* only Authenticated Access */}
          <Route path='/' element={isAuthenticated ? <Overview app={appConfig} /> : <Error403 app={appConfig} />} />
          <Route path='/results' element={isAuthenticated ? <Results app={appConfig} /> : <Error403 app={appConfig} />} />
          <Route path='/statistics' element={isAuthenticated ? <Statistics app={appConfig} /> : <Error403 app={appConfig} />} />
          <Route path='/personal-profile' element={isAuthenticated ? <MyProfile app={appConfig} /> : <Error403 app={appConfig} />} />
          <Route path='/admin-players' element={isAdmin ? <AdminPlayers app={appConfig} /> : <Error403 app={appConfig} />} />
          <Route path='/admin-results' element={isAdmin ? <AdminResults app={appConfig} /> : <Error403 app={appConfig} />} />
          <Route path='/create-player' element={isAdmin ? <CreatePlayer app={appConfig} /> : <Error403 app={appConfig} />} />
          <Route path='/modify-player' element={isAdmin ? <ModifyPlayer app={appConfig} /> : <Error403 app={appConfig} />} />
          <Route path='/create-match' element={isAdmin ? <CreateMatch app={appConfig} /> : <Error403 app={appConfig} />} />

          {/* only Unauthenticated Access */}
          <Route path='/login' element={isAuthenticated ? <Overview app={appConfig} /> : <Login app={appConfig} />} />

          {/* both Access */}
          <Route path='/error-403' element={<Error403 app={appConfig} />} />
          <Route path='*' element={<Error404 app={appConfig} />} />
        </Routes>
      }
      navigation={<AppNavigation />}
      tools={<HelpPanel
        header={<h2>Anleitung</h2>}
      >
        <div>
          Um den aktuell führenden der Rangliste zu ermitteln, wird jedes gespielte Spiel eingetragen. Um die Wichtigkeit mancher Spiele im Ranking wieder zu finden, gibt es pro Art unterschiedliche Multiplikatoren. Die gewonnenen Punkte werden immer mit dem Multiplikator berechnet.
          <br></br>
          <h3>Punkte</h3>
          <ul>
            <li><b>Sieg:</b> 10 Punkte</li>
            <li><b>Unentschieden:</b> 5 Punkte</li>
            <li><b>Niederlage:</b> 0 Punkte</li>
          </ul>
          <h3>Multiplikator</h3>
          <ul>
            <li><b>Friendly:</b> 0,25</li>
            <li><b>Turnier-Gruppenphase:</b> 0,5</li>
            <li><b>Turnier-KO-Runde:</b> 0,75</li>
            <li><b>Turnier-Finale:</b> 1,00</li>
            <li><b>Liga:</b> 0,75</li>
          </ul>
          <h3>Tabelle</h3>
          <ul>
            <li>Die Rangliste ist sortiert nach <b>gewonnen Punkten</b></li>
            <li>Es zählen nur die Punkte der <b>letzten zwei Jahre</b></li>
            <li>Die Punkte werden auf <b>2 Nachkommastellen</b> berechnet</li>
            <li>Bei gleicher Punktzahl wird auf die Anzahl der gespielten Spiele geguckt. Derjenige mit weniger Spielen, steht in der Tabelle weiter oben</li>
          </ul>
        </div>
      </HelpPanel>}
    />
  );
}

export default App;
