import { Box, Container, ContentLayout, Grid, Header, PieChart } from "@cloudscape-design/components";
import { PageHeader } from "../commons/pageHeader";
import { getMatchesByType, getNumberOfMatches } from "../services/firestore";


export function Statistics(props: any) {
    const pageConfig = {
        name: "Statistics",
        url: "/statistics",
        description: "Alle Statistiken Infos auf einen Blick"
    }

    return (
        <ContentLayout
            header={<PageHeader app={props.app} page={pageConfig}></PageHeader>}
        >
            <Grid
                gridDefinition={[
                    { colspan: { default: 12, xxs: 6 } },
                    { colspan: { default: 12, xxs: 6 } }
                ]}
            >
                <Container
                    header={
                        <Header variant="h2">
                            Matches by Type
                        </Header>
                    }>
                    <PieChart
                        data={getMatchesByType()}
                        hideFilter
                        innerMetricDescription="total matches played"
                        innerMetricValue={"" + getNumberOfMatches()}
                        size="large"
                        variant="donut"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    There is no data available
                                </Box>
                            </Box>
                        }
                        noMatch={
                            <Box textAlign="center" color="inherit">
                                <b>No matching data</b>
                                <Box variant="p" color="inherit">
                                    There is no matching data to display
                                </Box>
                            </Box>
                        }
                    />
                </Container>
            </Grid>
        </ContentLayout>
    );
}