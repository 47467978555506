import { Box, BreadcrumbGroup, ButtonDropdown, ButtonDropdownProps, Header } from "@cloudscape-design/components";
import { auth } from "../services/firebase";

function itemClicked(e: CustomEvent<ButtonDropdownProps.ItemClickDetails>){
    switch (e.detail.id) {
        case "logout":
            auth.signOut()
            break;
    
        default:
            break;
    }
}

function SettingsButton (props: any){
    return (<ButtonDropdown items={[
        {
            text: "Logout",
            iconName: "undo",
            id:"logout"
        }
    ]}
    onItemClick={e => itemClicked(e)}
    >{auth.currentUser?.displayName}</ButtonDropdown>)
}

export function PageHeader(props: any) {
    return (
        <Box>
            <BreadcrumbGroup items={[{ text: props.app.name, href: props.app.url }, { text: props.page.name, href: props.page.url }]}></BreadcrumbGroup>
            <Header variant="h1" description={props.page.description} actions={props.page.name === "Login" ? "":<SettingsButton></SettingsButton>}>{props.page.name}</Header> 
        </Box>
    )
}