import { Box, ColumnLayout, Container, ContentLayout, Header, Link, PieChart, SpaceBetween } from "@cloudscape-design/components";
import { PageHeader } from "../commons/pageHeader";
import { getPlayedMatchesOfUser, getPointsOfUser, getTable, getUserID, getWonMatchesofUser } from "../services/firestore";
import { auth } from "../services/firebase";


export function MyProfile(props: any) {
    const pageConfig = {
        name: "My Profile",
        url: "/personal-profile",
        description: "Alle deine Infos auf einen Blick"
    }

    console.log(auth.currentUser)

    return (
        <ContentLayout
            header={<PageHeader app={props.app} page={pageConfig}></PageHeader>}
        >
            <SpaceBetween size={"s"}>
                <Container
                    header={
                        <Header
                            variant="h2"
                        >
                            Statistics
                        </Header>
                    }>
                    <ColumnLayout variant="text-grid" columns={3}>
                        <div>
                            <Box variant="awsui-key-label">Position</Box>
                            <Link variant="awsui-value-large" href="#">
                                {getTable().find(item => item.points === getPointsOfUser(getUserID(auth.currentUser?.email)))?.position}
                            </Link>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Matches Played</Box>
                            <Link variant="awsui-value-large" href="#">
                                {getPlayedMatchesOfUser(getUserID(auth.currentUser?.email))}
                            </Link>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Points</Box>
                            <Link variant="awsui-value-large" href="#">
                                {getPointsOfUser(getUserID(auth.currentUser?.email))}
                            </Link>
                        </div>
                    </ColumnLayout>
                </Container>
                <Container>
                    <PieChart
                        data={[
                            { title: "Matches won", value: getWonMatchesofUser(getUserID(auth.currentUser?.email)) },
                            { title: "Matches lost", value: getPlayedMatchesOfUser(getUserID(auth.currentUser?.email)) - getWonMatchesofUser(getUserID(auth.currentUser?.email)) }
                        ]}
                        hideFilter
                        hideLegend
                        innerMetricDescription="total matches played"
                        innerMetricValue={getPlayedMatchesOfUser(getUserID(auth.currentUser?.email)) + ""}
                        size="large"
                        variant="donut"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    There is no data available
                                </Box>
                            </Box>
                        }
                        noMatch={
                            <Box textAlign="center" color="inherit">
                                <b>No matching data</b>
                                <Box variant="p" color="inherit">
                                    There is no matching data to display
                                </Box>
                            </Box>
                        }
                    />
                </Container>
            </SpaceBetween>
        </ContentLayout>
    );
}