import { Button, Container, ContentLayout, Form, FormField, Header, Input, Select, SpaceBetween } from "@cloudscape-design/components"
import { PageHeader } from "../commons/pageHeader";
import React from "react";
import { createMatch, getAllPlayers } from "../services/firestore";
import { useNavigate } from "react-router-dom";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

export function CreateMatch(props: any) {
    const pageConfig = {
        name: "Create Match",
        url: "/create-match",
        description: "Neues Match erstellen"
    }

    const [selectedOptionP1, setSelectedOptionP1] = React.useState({} as OptionDefinition);
    const [selectedOptionP2, setSelectedOptionP2] = React.useState({} as OptionDefinition);
    const [score1, setScore1] = React.useState(0);
    const [score2, setScore2] = React.useState(0);
    const [type, setType] = React.useState({} as OptionDefinition);

    const navigate = useNavigate()
    const playerOptions = getAllPlayers().map(player => ({
        label: player.displayName,
        value: player.id
    }))
    const typeOptions = [
        { label: "friendly", value: "friendly", description: "coefficient: 0.25" },
        { label: "league", value: "league", description: "coefficient: 0.75" },
        { label: "tournament-group", value: "tournament-group", description: "coefficient: 0.50" },
        { label: "tournament-knockout", value: "tournament-knockout", description: "coefficient: 0.75" },
        { label: "tournament-final", value: "tournament-final", description: "coefficient: 1.00" },
    ]

    return (
        <ContentLayout
            header={<PageHeader app={props.app} page={pageConfig}></PageHeader>}
        >

            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={e => navigate("/admin-players")} variant="link">
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={e => {
                            createMatch(selectedOptionP1, selectedOptionP2, score1, score2, type).then(result => navigate("/admin-results"))
                        }}>Submit</Button>
                    </SpaceBetween>
                }
            >
                <SpaceBetween size={"m"}>
                    <Container
                        header={
                            <Header variant="h2">
                                Players
                            </Header>
                        }>
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="Player 1">
                                <Select selectedOption={selectedOptionP1} onChange={e => setSelectedOptionP1(e.detail.selectedOption)} options={playerOptions} />
                            </FormField>
                            <FormField label="Player 2">
                                <Select selectedOption={selectedOptionP2} onChange={e => setSelectedOptionP2(e.detail.selectedOption)} options={playerOptions} />
                            </FormField>
                        </SpaceBetween>
                    </Container>
                    <Container
                        header={
                            <Header variant="h2">
                                Score
                            </Header>
                        }>
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="Score Player 1">
                                <Input value={score1.toString()} type="number" onChange={e => setScore1(+e.detail.value)} />
                            </FormField>
                            <FormField label="Score Player 2">
                                <Input value={score2.toString()} type="number" onChange={e => setScore2(+e.detail.value)} />
                            </FormField>
                        </SpaceBetween>
                    </Container>
                    <Container
                        header={
                            <Header variant="h2">
                                Match Info
                            </Header>
                        }>
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="Type">
                                <Select selectedOption={type} onChange={e => setType(e.detail.selectedOption)} options={typeOptions} />
                            </FormField>
                        </SpaceBetween>
                    </Container>
                </SpaceBetween>
            </Form>

        </ContentLayout>
    )
}