import { SideNavigation } from "@cloudscape-design/components"
import { useNavigate } from "react-router-dom"

export function AppNavigation() {
    const navigate = useNavigate()
    
    return (<SideNavigation
        header={{ href: "/", text: "OLX Darts Ranking" }}
        activeHref={window.location.pathname}
        items={[
          { type: "link", text: "Overview", href: "/" },
          { type: "link", text: "Statistics", href: "/statistics"},
          { type: "link", text: "Results", href: "/results" },
          { type: "divider" },
          {
            type: "section-group",
            title: "Personal Area",
            items: [
              {
                type: "link",
                text: "My Profile",
                href: "/personal-profile"
              }
            ]
          },
          { type: "divider" },
          {
            type: "section-group",
            title: "Admin Area",
            items: [
              {
                type: "link",
                text: "Players",
                href: "/admin-players"
              },
              {
                type: "link",
                text: "Results",
                href: "/admin-results"
              }
            ]
          }]}
        onFollow={event => {
          event.preventDefault()
          navigate(event.detail.href)
        }}
      />)
}